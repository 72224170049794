import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, PricingSettingsConfiguration } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class PricingSettingsGQL extends Query<
  Configuration<PricingSettingsConfiguration>,
  ConfigurationQueryVariables
> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!) {
      configuration(marketKey: $marketKey) {
        pricingSettings {
          cartTaxGroups
          ecoFeeEnabled
        }
      }
    }
  `;
}

import { inject, Injectable, Signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

import { UserService } from '@ppg/auth';

import { UserInfoResponse } from '../../modules/customer/models/user-info-response.model';

/**
 * @deprecated use UserService from @ppg/auth
 *
 * There is a problem with Types between CDP and CDE they uses different sources of types but they are compatible and the same
 */
@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  #userService = inject(UserService);
  user: Signal<UserInfoResponse | null> = this.#userService.user as unknown as Signal<UserInfoResponse | null>;

  user$ = toObservable(this.#userService.user) as unknown as Observable<UserInfoResponse | null>;

  getUserInfo() {
    return this.user$;
  }
}

import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs';

import { CountryService } from './country.service';
import { MarketBrandProviderService } from '../../core/services/market-brand-provider.service';
import { LanguageService } from '../../modules/preference/services/language.service';
import { MarketLabels, MARKET_LABELS } from '../consts/market-labels';
import { CountryCode } from '../enums/country-code.enum';
import { Locale } from '../enums/locale.enum';
import { Market } from '../models/market.model';

@Injectable({
  providedIn: 'root',
})
export class MarketLabelProviderService {
  private _market: CountryCode;
  private defaultLabels = MARKET_LABELS[CountryCode.US][Locale.en_US];

  constructor(
    private transloco: TranslocoService,
    private languageService: LanguageService,
    private marketBrandProviderService: MarketBrandProviderService,
  ) {}

  set market(market: CountryCode) {
    if (!market || !Object.values(CountryCode).includes(market)) {
      throw new Error(`Unacceptable market value: ${market}`);
    }
    this._market = market;
  }

  get market(): Market {
    if (!this._market) {
      this.market = CountryService.DEFAULT_COUNTRY_CODE;
    }
    return this.marketBrandProviderService.getMarket(this._market);
  }

  getLabelsForMarket(market: CountryCode, locale: Locale): MarketLabels {
    return MARKET_LABELS[market]?.[locale] ?? this.defaultLabels;
  }

  get labels() {
    return this.getLabelsForMarket(this._market, this.transloco.getActiveLang() as Locale);
  }

  get labels$() {
    return this.languageService.currentLanguage$.pipe(
      map((lang) => this.getLabelsForMarket(this._market, lang as Locale)),
    );
  }
}

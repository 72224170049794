import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BusinessUnitProviderService {
  getACBusinessUnitCode() {
    // MVP
    return 'AC';
  }
}

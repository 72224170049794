import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, GlobalSettingsConfiguration } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsGQL extends Query<Configuration<GlobalSettingsConfiguration>, ConfigurationQueryVariables> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!, $locale: String) {
      configuration(marketKey: $marketKey, locale: $locale) {
        globalSettings
      }
    }
  `;
}

import { Injectable } from '@angular/core';

import { BrandProviderService } from './brand-provider.service';
import { BusinessUnitProviderService } from './business-unit-provider.service';
import { CountryCodeWithBusinessUnit } from '../../shared/enums/country-code-with-business-unit.enum';
import { CustomColorFlowType } from '../../shared/models/custom-color-flow-type';
import { Market, MarketBrand } from '../../shared/models/market.model';

@Injectable({
  providedIn: 'root',
})
export class MarketBrandProviderService {
  constructor(
    private businessUnitProviderService: BusinessUnitProviderService,
    private brandProviderService: BrandProviderService,
  ) {}

  getMarketBrand(countryCode: string): MarketBrand {
    const brand = this.brandProviderService.getGenericBrand();
    return new MarketBrand(this.getMarket(countryCode), brand);
  }

  getMarket(countryCode: string): Market {
    const businessUnitCode = this.businessUnitProviderService.getACBusinessUnitCode();
    return new Market(businessUnitCode, countryCode);
  }

  getMarketCustomColorFlow(countryCode: string): CustomColorFlowType {
    const marketKey = this.getMarket(countryCode)?.key;

    switch (marketKey) {
      case CountryCodeWithBusinessUnit.AC_US:
        return 'AC-US';
      case CountryCodeWithBusinessUnit.AC_CA:
        return 'AC-CA';
      case CountryCodeWithBusinessUnit.AC_GB:
        return 'AC-GB';
      default:
        return null;
    }
  }
}

import { MarketCountry } from '../models/market-country.model';

export const US_MarketCountries: MarketCountry[] = [
  { code: 'US', name: 'United States', isDefault: true },
  { code: 'PR', name: 'Puerto Rico', isDefault: false },
];

export const CA_MarketCountries: MarketCountry[] = [{ code: 'CA', name: 'Canada', isDefault: true }];

export const GB_MarketCountries: MarketCountry[] = [{ code: 'GB', name: 'United Kingdom', isDefault: true }];

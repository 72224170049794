import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, ShowDataSheets } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class ShowDataSheetsGQL extends Query<Configuration<ShowDataSheets>, ConfigurationQueryVariables> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!) {
      configuration(marketKey: $marketKey) {
        productSettings {
          showDataSheets
        }
      }
    }
  `;
}

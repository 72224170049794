import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { CountriesResponse } from '../models/countries-response.model';

@Injectable({
  providedIn: 'root',
})
export class AllCountriesGQL extends Query<CountriesResponse> {
  // eslint-disable-next-line
  override document = gql`
    query {
      countries {
        items {
          code
          currencyCode
          isStateRequired
          name
          states {
            code
            name
            shortCode
          }
        }
      }
    }
  `;
}

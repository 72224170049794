import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { CA_MarketCountries, GB_MarketCountries, US_MarketCountries } from '../consts/market-countries';
import { CountryCode } from '../enums/country-code.enum';
import { PaymentMarketCountryCode } from '../enums/payment-market-country-code.enum';
import { AllCountriesGQL } from '../queries/all-countries.query';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  public static readonly DEFAULT_COUNTRY_CODE: CountryCode = CountryCode.US;
  private readonly countriesMax10CharactersPostcode: string[] = [CountryCode.US];
  private readonly countriesMax8CharactersPostcode: string[] = [CountryCode.GB];
  private readonly countriesMax7CharactersPostcode: string[] = [CountryCode.CA];

  constructor(private allCountriesGQL: AllCountriesGQL) {}

  getAll(isAnonymous: boolean = false) {
    return this.allCountriesGQL
      .fetch({}, { fetchPolicy: 'cache-first', context: { clientName: isAnonymous ? 'anonymous' : '' } })
      .pipe(map((result) => result.data.countries.items));
  }

  getCountryPostcodeMaxCharacters(country: string): number {
    if (this.countriesMax10CharactersPostcode.includes(country)) {
      return 10;
    } else if (this.countriesMax8CharactersPostcode.includes(country)) {
      return 8;
    } else if (this.countriesMax7CharactersPostcode.includes(country)) {
      return 7;
    }
  }

  mapFromElevateCountryCode(elevateCountryCode: string): string {
    switch (elevateCountryCode) {
      case 'USA':
        return 'US';
      case 'CANADA':
        return 'CA';
      default:
        throw new Error(`Unhandled elevateCountryCode: ${elevateCountryCode}`);
    }
  }

  getMarketSpecificCountries(countryCode: CountryCode) {
    switch (countryCode) {
      case CountryCode.US:
        return US_MarketCountries;
      case CountryCode.CA:
        return CA_MarketCountries;
      case CountryCode.GB:
        return GB_MarketCountries;
      default:
        throw new Error(`Unhandled country: ${countryCode}`);
    }
  }

  getPaymentMarketCountryName(code: PaymentMarketCountryCode) {
    switch (code) {
      case PaymentMarketCountryCode.US:
        return 'United States';
      case PaymentMarketCountryCode.PR:
        return 'Puerto Rico';
      case PaymentMarketCountryCode.CA:
        return 'Canada';
      case PaymentMarketCountryCode.GB:
        return 'United Kingdom';
      default:
        throw new Error(`Unhandled country: ${code}`);
    }
  }
}

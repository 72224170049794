import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, PaymentSettingsConfiguration } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class PaymentSettingsGQL extends Query<
  Configuration<PaymentSettingsConfiguration>,
  ConfigurationQueryVariables
> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!) {
      configuration(marketKey: $marketKey) {
        paymentSettings {
          paymentSourceProvider
          returnUrl
          marketProxy
        }
      }
    }
  `;
}

export class Market {
  businessUnit: string;
  countryCode: string;

  constructor(businessUnitCode: string, countryCode: string) {
    this.businessUnit = businessUnitCode;
    this.countryCode = countryCode;
  }

  public get key() {
    return `${this.businessUnit}-${this.countryCode}`;
  }
}

export class MarketBrand {
  market: Market;
  brand: string;

  constructor(market: Market, brand: string) {
    this.market = market;
    this.brand = brand;
  }

  public get key() {
    return `${this.market.key}-${this.brand}`;
  }
}

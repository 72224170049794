import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs';

import { LanguagesService } from '@ppg/core/language';

import { LanguagesQuery } from '../queries/languages.query';

/**
 * @deprecated use {@link LanguagesService.language} from @ppg/core/language
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  readonly #languagesService = inject(LanguagesService);
  /**
   * @deprecated use {@link LanguagesService.language} from @ppg/core/language
   */
  readonly currentLanguage = this.#languagesService.language;
  /**
   * @deprecated use {@link LanguagesService.language$} from @ppg/core/language
   */
  readonly currentLanguage$ = this.#languagesService.language$;

  constructor(private languagesQuery: LanguagesQuery) {}

  getLanguages(countryCode: string, isAnonymous: boolean = false) {
    return this.languagesQuery
      .fetch({ countryCode }, { context: { clientName: isAnonymous ? 'anonymous' : '' } })
      .pipe(map((res) => res.data.languages));
  }
}

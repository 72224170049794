import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrandProviderService {
  getGenericBrand() {
    // MVP
    return 'Generic';
  }
}

import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Language } from '../models/language.model';

@Injectable({
  providedIn: 'root',
})
export class LanguagesQuery extends Query<{ languages: Language[] }, { countryCode: string }> {
  // eslint-disable-next-line
  override document = gql`
    query ($countryCode: NonEmptyString!) {
      languages(countryCode: $countryCode) {
        tag
        displayName
        isDefault
        code
        countryCode
        region
        flagCountryCode
      }
    }
  `;
}

import { CountryCode } from '../enums/country-code.enum';
import { Locale } from '../enums/locale.enum';
const path = '/assets/documents';
export const COOKIE_CSS_CLASS = 'cookie-consent__edit';
export type MarketLabels = (typeof MARKET_LABELS)[CountryCode.US][Locale.en_US];

export const MARKET_LABELS = {
  [CountryCode.US]: {
    [Locale.en_US]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.ppg.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Legal Notices & Privacy Policies',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
            text: 'PPG Terms of Use',
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'PPG Paints App',
      PAGE_TITLES: {
        APP_NAME: 'PPG Paints App',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account',
        REGISTRATION: 'Form - PPG Registration',
        REGISTRATION_COMPLETED: 'Completed - PPG Registration',
        REGISTRATION_VERIFIED: 'Verified - PPG Registration',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'I have reviewed my order and agree to the',
          link: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'terms and conditions.',
        },
        {
          label: 'I acknowledge the',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'PPG Paints Store Return Policy.',
        },
        {
          label:
            'I acknowledge that the final invoice total will be determined at the time of processing and will include the contract pricing less any applicable promotions. Taxes will be based on the state of origin and delivery and final subtotal after any pricing, promotions or quantity adjustments. Inventory is not guaranteed until the order is processed.',
          name: 'termsAndConditions3',
        },
      ],
    },
    [Locale.es_US]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.ppg.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Avisos legales y políticas de privacidad',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
            text: 'Términos de uso de PPG',
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'PPG Paints App',
      PAGE_TITLES: {
        APP_NAME: 'PPG Paints App',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account',
        REGISTRATION: 'Formulario - Registro PPG',
        REGISTRATION_COMPLETED: 'Completado - Registro PPG',
        REGISTRATION_VERIFIED: 'Verificación - Registro PPG',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'He revisado mi pedido y acepto los',
          link: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'términos y condiciones.',
        },
        {
          label: 'Reconozco la',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'Política de devolución de PPG Paints Store.',
        },
        {
          label:
            'Reconozco que el total de la factura final se determinará en el momento del procesamiento e incluirá el precio del contrato menos las promociones aplicables. Los impuestos se basarán en el estado de origen y entrega y el subtotal final después de cualquier ajuste al precio, promoción o cantidad. El inventario no está garantizado hasta que se procese el pedido.',
          name: 'termsAndConditions3',
        },
      ],
    },
  },
  [CountryCode.GB]: {
    [Locale.en_GB]: {
      FOOTER: {
        LINKS: [
          {
            url: `${path}/200409_PPG_Website_Terms_of_Use.pdf`,
            text: 'Terms of Use',
          },
          {
            url: `${path}/200409_PPG_Architectural_Coatings_UK_Limited_Website_Terms_and_Conditions_of_SaleV3.pdf`,
            text: 'Terms & Conditions of Sale',
          },
        ],
      },
      APP_NAME: 'PPG ProSight',
      PAGE_TITLES: {
        APP_NAME: 'PPG ProSight',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account',
        REGISTRATION: 'Form - PPG Registration',
        REGISTRATION_COMPLETED: 'Completed - PPG Registration',
        REGISTRATION_VERIFIED: 'Verified - PPG Registration',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'I agree to the',
          link: 'assets/documents/200409_PPG_Architectural_Coatings_UK_Limited_Website_Terms_and_Conditions_of_SaleV3.pdf',
          name: 'termsAndConditions1',
          linkLabel: 'terms and conditions.',
        },
        {
          label:
            'The price of the Goods are those agreed in writing from time to time between you and PPG. Please contact your account manager or decorating centre before submitting your order for details of the current prices or for any further information.',
          name: 'termsAndConditions2',
        },
        {
          label:
            'We are unable to accept returns of tinted paint, please ensure you request the right colour before placing your order or contact your decorating centre for more information.',
          name: 'termsAndConditions3',
        },
      ],
    },
  },
  [CountryCode.CA]: {
    [Locale.en_CA]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.ppg.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Legal Notices & Privacy Policies',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
            text: 'PPG Terms of Use',
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'Dulux Paints App',
      PAGE_TITLES: {
        APP_NAME: 'Dulux Paints App',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account',
        REGISTRATION: 'Formulaire - PPG Inscription PPG',
        REGISTRATION_COMPLETED: 'Terminé - PPG Inscription PPG',
        REGISTRATION_VERIFIED: 'Vérification - PPG Inscription PPG',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'I have reviewed my order and agree to the',
          link: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'terms and conditions.',
        },
        {
          label: 'I acknowledge the',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'Dulux Paints Store Return Policy.',
        },
        {
          label:
            'I acknowledge that the final invoice total will be determined at the time of processing and will include the contract pricing less any applicable promotions. Taxes will be based on the province of origin and delivery and final subtotal after any pricing, promotions or quantity adjustments. Inventory is not guaranteed until the order is processed.',
          name: 'termsAndConditions3',
        },
      ],
    },
    [Locale.fr_CA]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.ppg.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Avis juridiques et politiques de confidentialité',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
            text: `Conditions d'utilisation de PPG`,
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'Dulux Paints App',
      PAGE_TITLES: {
        APP_NAME: 'Dulux Paints App',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account',
        REGISTRATION: 'Form - PPG Registration',
        REGISTRATION_COMPLETED: 'Completed - PPG Registration',
        REGISTRATION_VERIFIED: 'Verified - PPG Registration',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: `J'ai examiné ma commande et j'accepte les`,
          link: 'https://www.ppgac.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'termes et conditions.',
        },
        {
          label: 'Je reconnais la politique de',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'retour des magasins Bétonel Dulux.',
        },
        {
          label: `Je reconnais que le total final de la facture sera déterminé au moment du traitement et inclura les tarifs contractuels moins toutes promotions applicables. Les taxes seront basées sur la province d'origine et la livraison, ainsi que le sous-total final après tout ajustement de tarifs, promotions ou quantité. L'inventaire n'est pas garanti tant que la commande n'est pas traitée.`,
          name: 'termsAndConditions3',
        },
      ],
    },
  },
  [CountryCode.SE]: {
    [Locale.sv_SE]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.alcro.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Legal Notices & Privacy Policies SE',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.alcro.com/privacy-policy/#terms-of-use',
            text: 'PPG Terms of Use SE',
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'Alcro App SE',
      PAGE_TITLES: {
        APP_NAME: 'Alcro App SE',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account SE',
        REGISTRATION: 'Form - PPG Registration SE',
        REGISTRATION_COMPLETED: 'Completed - PPG Registration SE',
        REGISTRATION_VERIFIED: 'Verified - PPG Registration SE',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'I have reviewed my order and agree to the SE',
          link: 'https://www.alcro.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'terms and conditions.',
        },
        {
          label: 'I acknowledge the SE',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'PPG Paints Store Return Policy.',
        },
        {
          label:
            'I acknowledge that the final invoice total will be determined at the time of processing and will include the contract pricing less any applicable promotions. Taxes will be based on the state of origin and delivery and final subtotal after any pricing, promotions or quantity adjustments. Inventory is not guaranteed until the order is processed. SE',
          name: 'termsAndConditions3',
        },
      ],
    },
  },
  [CountryCode.NL]: {
    [Locale.nl_NL]: {
      FOOTER: {
        LINKS: [
          {
            url: 'https://corporate.histor.com/Legal-Notices-and-Privacy-Policy.aspx',
            text: 'Legal Notices & Privacy Policies NL',
            dataTestId: 'FooterComponent.LegalNotice',
          },
          {
            url: 'https://www.histor.com/privacy-policy/#terms-of-use',
            text: 'PPG Terms of Use NL',
            dataTestId: 'FooterComponent.TermsOfUse',
          },
        ],
      },
      APP_NAME: 'Histor App NL',
      PAGE_TITLES: {
        APP_NAME: 'Histor App NL',
        CREATE_ACCOUNT: 'Create Your PPG Paints Account NL',
        REGISTRATION: 'Form - PPG Registration NL',
        REGISTRATION_COMPLETED: 'Completed - PPG Registration NL',
        REGISTRATION_VERIFIED: 'Verified - PPG Registration NL',
      },
      TERMS_AND_CONDITIONS: [
        {
          label: 'I have reviewed my order and agree to the NL',
          link: 'https://www.histor.com/privacy-policy/#terms-of-use',
          name: 'termsAndConditions1',
          linkLabel: 'terms and conditions.',
        },
        {
          label: 'I acknowledge the NL',
          link: 'assets/documents/ReturnPolicy.html',
          name: 'termsAndConditions2',
          linkLabel: 'PPG Paints Store Return Policy.',
        },
        {
          label:
            'I acknowledge that the final invoice total will be determined at the time of processing and will include the contract pricing less any applicable promotions. Taxes will be based on the state of origin and delivery and final subtotal after any pricing, promotions or quantity adjustments. Inventory is not guaranteed until the order is processed. NL',
          name: 'termsAndConditions3',
        },
      ],
    },
  },
};

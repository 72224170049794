import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, ShowStoreOrders } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class CanShowStoreOrdersGQL extends Query<Configuration<ShowStoreOrders>, ConfigurationQueryVariables> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!) {
      configuration(marketKey: $marketKey) {
        orderSettings {
          showStoreOrders
        }
      }
    }
  `;
}

import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Query } from 'apollo-angular';

import { Configuration, ConfigurationQueryVariables, DeliveryStoreSelectionAllowed } from '../models/configuration';

@Injectable({
  providedIn: 'root',
})
export class DeliveryStoreSelectionAllowedGQL extends Query<
  Configuration<DeliveryStoreSelectionAllowed>,
  ConfigurationQueryVariables
> {
  // eslint-disable-next-line
  override document = gql`
    query ($marketKey: String!) {
      configuration(marketKey: $marketKey) {
        cartSettings {
          deliveryStoreSelectionAllowed
        }
      }
    }
  `;
}
